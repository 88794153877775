const context = cast.framework.CastReceiverContext.getInstance();
const castDebugLogger = cast.debug.CastDebugLogger.getInstance();

const playerManager = context.getPlayerManager();

//Logs events in the console
context.addEventListener(cast.framework.system.EventType.READY, () => {
  // Enable debug logger and show a 'DEBUG MODE' overlay at top left corner.
  castDebugLogger.setEnabled(process.env.WEB_ENVIRONMENT === "dev");
  // Show debug overlay
  castDebugLogger.showDebugLogs(process.env.WEB_ENVIRONMENT === "dev");
  // Clear log messages on debug overlay
  castDebugLogger.clearDebugLogs();
});

castDebugLogger.loggerLevelByEvents = {
  "cast.framework.events.category.CORE": cast.framework.LoggerLevel.INFO,
  "cast.framework.events.EventType.MEDIA_STATUS":
    cast.framework.LoggerLevel.DEBUG,
};

playerManager.setMessageInterceptor(
  cast.framework.messages.MessageType.LOAD,
  (loadRequestData) => {
    console.log("LOAD message received:", loadRequestData);

    const drmData = extractDRMData(
      loadRequestData.media?.customData || loadRequestData.customData // Theoplayer sends customData outside of media object
    );
    if (drmData) {
      configureDRM(drmData);
    } else {
      console.error("No DRM configuration found.");
    }

    return loadRequestData;
  }
);

function extractDRMData(customData: any) {
  if (!customData) return null;

  if (
    customData.drm &&
    customData.drm.protectionSystem &&
    customData.drm.licenseUrl
  ) {
    return customData.drm;
  }

  if (customData.sourceDescription) {
    const source =
      customData.sourceDescription.sources[0].contentProtection.widevine;
    console.log("source", source);

    return {
      protectionSystem: "widevine",
      licenseUrl: source.licenseAcquisitionURL,
      headers: source.headers,
    };
  }

  if (customData.licenseAcquisitionURL || customData.exoPlayerConfig) {
    return {
      protectionSystem: "widevine",
      licenseUrl:
        customData.licenseAcquisitionURL ||
        customData.exoPlayerConfig.licenseUrl,
      headers: customData.headers || customData.exoPlayerConfig.headers,
    };
  }

  return null;
}

function configureDRM(drmData: any) {
  playerManager.setMediaPlaybackInfoHandler((_, playbackConfig) => {
    playbackConfig.protectionSystem = drmData.protectionSystem;
    playbackConfig.licenseUrl = drmData.licenseUrl;

    if (drmData.headers) {
      playbackConfig.licenseRequestHandler = (requestInfo) => {
        requestInfo.headers = drmData.headers;
        console.log("License request headers set:", requestInfo.headers);
      };
    }
    console.log("DRM playback configuration completed:", playbackConfig);
    return playbackConfig;
  });
}

context.start();
